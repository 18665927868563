@font-face {
  font-family: "Helvetica Now Display";
  src: url("./assets/fonts/HelveticaNowDisplayRegular.woff2") format("woff2"),
    url("./assets/fonts/HelveticaNowDisplayRegular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Now Display";
  src: url("./assets/fonts/HelveticaNowDisplayMedium.woff2") format("woff2"),
    url("./assets/fonts/HelveticaNowDisplayMedium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Now Text";
  src: url("./assets/fonts/HelveticaNowTextBold.woff2") format("woff2"),
    url("./assets/fonts/HelveticaNowTextBold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Now Text";
  src: url("./assets/fonts/HelveticaNowTextMedium.woff2") format("woff2"),
    url("./assets/fonts/HelveticaNowTextMedium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Now Text";
  src: url("./assets/fonts/HelveticaNowTextRegular.woff2") format("woff2"),
    url("./assets/fonts/HelveticaNowTextRegular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Space Mono";
  src: url("./assets/fonts/spacemono.woff2") format("woff2"),
    url("./assets/fonts/spacemono.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

.formLabel {
  @apply text-xxs font-medium uppercase tracking-widest;
}

.sideNavLink {
  @apply flex w-full cursor-pointer truncate rounded-full px-2 py-1 duration-200 ease-in;
}

.input {
  @apply border-ash focus:ring-green focus:border-green mt-1 flex w-full rounded-md border px-2 py-2 shadow-none focus:ring-1 focus-visible:outline-none;
}

.button {
  @apply focus:ring-green inline-flex items-center whitespace-nowrap rounded-full px-4 py-2 duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2;
}

.button-large {
  @apply focus:ring-green inline-flex items-center whitespace-nowrap rounded-full px-5 py-3 duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2;
}

.button-transparent {
  @apply hover:bg-green border-2 border-black bg-transparent text-black hover:text-black;
}

.button-black {
  @apply hover:bg-green border-2 border-black bg-black text-white hover:text-black;
}

.button-green {
  @apply bg-green text-black hover:bg-black hover:text-white;
}

.btnTertiary {
  @apply border-ash items-center rounded-full border bg-white px-4 py-2 text-left text-xs duration-200 ease-in;
}

.btnTertiaryActive {
  @apply hover:bg-bone focus:bg-bone cursor-pointer hover:border-black focus:outline-none focus:ring-1 focus:ring-black;
}

.headline {
  @apply font-display font-normal;
}

.body-large {
  @apply text-lg;
}

.button-arrow {
  @apply ml-2 stroke-current;
}

.button-arrow line:last-child {
  @apply opacity-0;
}

.button:hover .button-arrow line:last-child {
  @apply opacity-100;
  stroke-dasharray: 2 4;
  animation: dash 10s linear infinite;
}

.button-arrow-small {
  @apply ml-2 shrink-0 stroke-current;
}

code {
  font-variant-ligatures: none;
}

@keyframes dash {
  to {
    stroke-dashoffset: 80;
  }
}
